body,html {
  margin: 0;
  padding: 0;
  width: 100%;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  background-image: url("./Assets/bg2.jpg");
    flex-direction: column;
}




::-webkit-scrollbar{
  width: 0.1rem;
  background-color: #00000029;
}

::-webkit-scrollbar:hover{
  width: 0.3rem;
  background-color: #00000029;
}



::-webkit-scrollbar-thumb{
  background-color: #1E4711;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #B32A31;
}

::-webkit-scrollbar-track{
  background-color: #E0C180;
}


:root{
  font-family: monserat;

}

@font-face {
  font-family: monserat;
  src: url('./Fonts/FontsFree-Net-Montserrat-Medium.ttf');
}

@font-face {
  font-family: monseratSemi;
  src: url('./Fonts/Montserrat-SemiBold.otf');
}


.slick-dots {
  position: absolute;
  bottom: 130px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 50px !important;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}


.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 50px !important;
  height: 50px !important;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
}




.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: rgb(255, 255, 255) !important;
  background-color: red !important;
  border: 2px solid rgb(255, 255, 255) !important;
  box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.3);
}


.slick-dots li button:before {
  font-family: 'auto' !important;
  font-size: 6px;
  line-height: 20px;
  border: 2px solid red !important;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: 17px !important;
  height: 17px !important;
  content: '';
  text-align: center;
  opacity: .75 !important;
  color: red !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: inherit;
  padding: 16px;
}


@media screen and (min-width: 280px) and (max-width: 1080px) {

  .slick-dots {
    position: absolute;
    bottom: 80px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
}