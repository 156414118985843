
@media(min-width:600px) {
           .flexy{
               display:flex;
           }  
        }







footer{
    height:fit-content;
    width:100%;
    background-color:rgb(236,67,48);
    margin-top:0px;
    padding:30px 70px;
    color:white
}
footer p{
    color:white;
    font-weight:bold
}

.footerlogo{
    margin-left:50px;
    width:500px
}

footer a{
    color:white;text-decoration:underline; 
}
footer a:hover{
    color:rgb(169,169,169)
}

.last{
   text-align:right
}

footer h3{
    font-weight:bold;
    margin-top:40px;
}

footer ul li a{
    font-size:24px;
    line-height:40px;
}

footer ul li a i{
    background-color:rgb(237, 120, 139);
    padding:15px;
    border-radius:50%;
    color:rgb(177, 32, 15);
    font-weight:900;
    margin:10px;
}

.none a{
    text-decoration:none;
}

.boda{
        background-color:rgb(151, 31, 17);
margin:0 !important;
color:white;
padding:10px 70px 0 70px
}

.boda a{
    color:white;text-decoration:none;
}





@media screen and (max-width: 620px) {

.footerlogo{
    margin-left:0px !important;
    width:250px !important
}

footer ul li a i{
  

    margin:auto !important;
}
.boda p{
    font-size:14px !important
}
}